<template>
  <customer-notifications v-if="$store.getters.isCustomer" />
  <contractor-notifications v-else-if="$store.getters.isContractor" />
</template>

<script>
import CustomerNotifications from './CustomerNotifications';
import ContractorNotifications from './ContractorNotifications';
export default {
  name: 'Notifications',
  components: {
    ContractorNotifications,
    CustomerNotifications},
};
</script>

<style scoped>

</style>
